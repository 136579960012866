import "../App.css";
import { ReactComponent as Parla } from "../images/Parla.svg";
import { ReactComponent as Logo } from "../images/logo_lang.svg";
import { ReactComponent as LandingBlobs } from "../images/landingBlobs.svg";
import { Link } from "react-router-dom";

const Language = () => {
  return (
    <>
      {/*<LandingBlobs className="fixed w-full h-full"/>*/}
      {/* <Parla /> */}
      <Logo />
      {/* <h1 className="font-semibold text-5xl">Welcome</h1> */}
      <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl text-center mt-2">Select a Language</p>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-5 justify-center items-center mx-auto max-w-screen-lg">
          <Link className="bg-[#424242] text-xs sm:text-sm md:text-base px-4 sm:px-6 md:px-8 lg:px-12 py-2 sm:py-3 md:py-4 lg:py-5 text-[#E2E2E2] border-solid border-2 rounded-full font-semibold cursor-pointer" to="/ai/en">
              English
          </Link>
          <Link className="bg-[#424242] text-xs sm:text-sm md:text-base px-4 sm:px-6 md:px-8 lg:px-12 py-2 sm:py-3 md:py-4 lg:py-5 text-[#E2E2E2] border-solid border-2 rounded-full font-semibold cursor-pointer" to="/ai/fr">
              Français
          </Link>
          <Link className="bg-[#424242] text-xs sm:text-sm md:text-base px-4 sm:px-6 md:px-8 lg:px-12 py-2 sm:py-3 md:py-4 lg:py-5 text-[#E2E2E2] border-solid border-2 rounded-full font-semibold cursor-pointer" to="/ai/de">
              Deutsch
          </Link>
          <Link className="bg-[#424242] text-xs sm:text-sm md:text-base px-4 sm:px-6 md:px-8 lg:px-12 py-2 sm:py-3 md:py-4 lg:py-5 text-[#E2E2E2] border-solid border-2 rounded-full font-semibold cursor-pointer" to="/ai/es">
              Español
          </Link>
          <Link className="bg-[#424242] text-xs sm:text-sm md:text-base px-4 sm:px-6 md:px-8 lg:px-12 py-2 sm:py-3 md:py-4 lg:py-5 text-[#E2E2E2] border-solid border-2 rounded-full font-semibold cursor-pointer" to="/ai/it">
              Italiano
          </Link>
          <Link className="bg-[#424242] text-xs sm:text-sm md:text-base px-4 sm:px-6 md:px-8 lg:px-12 py-2 sm:py-3 md:py-4 lg:py-5 text-[#E2E2E2] border-solid border-2 rounded-full font-semibold cursor-pointer" to="/ai/ko">
              Korean
          </Link>
      </div>


    </>
  );
};

export default Language;
