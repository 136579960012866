import {ReactComponent as Logo} from "../images/logo.svg";
import { Link } from "react-router-dom";


const ChatHeader = ({topic, darkMode}) => {
    return(
        <div className={`fixed top-0 left-0 right-0 flex items-center justify-between px-4 py-3 md:px-6 md:py-4 ${darkMode ? "bg-[#424242]" : "bg-white"} border-b-2 border-[#474747] z-10`}>
            <Link to="/" className="flex items-center">
                <Logo className="w-10 h-10 md:w-12 md:h-12" />
                <h1 className={`font-semibold text-lg md:text-xl lg:text-2xl ${darkMode ? "text-white" : "text-[#474747]"} ml-3 md:ml-4 italic`}>
                    Parla
                </h1>
            </Link>
            <p className={`text-xs md:text-sm font-semibold ${darkMode ? "text-white" : "text-[#474747]"} mt-1 md:mt-0`}>
                {topic}
            </p>
        </div>

    );
}

export default ChatHeader;