const ComfortRecord = ({score}) => {
    return (
        <div className="flex flex-col sm:flex-row justify-between items-center py-4 sm:py-7 px-4 sm:px-8">
            <h3 className="text-lg sm:text-xl font-semibold w-full text-center sm:text-left">
                Level of Comfort
            </h3>
            <p className="text-lg sm:text-xl font-semibold w-full text-center hidden sm:inline-block">
                <span className="block w-full border-t border-gray-300"></span>
            </p>
            <h3 className="text-lg sm:text-xl font-extrabold w-full text-center sm:text-right">
                {`${score ?? "100"}%`}
            </h3>
        </div>

    );
}

export default ComfortRecord