import {ReactComponent as BotLogo} from "../images/botLogo.svg"
import {ReactComponent as UserLogo} from "../images/userLogo.svg"


const ChatRecord = ({userMessage, botMessage}) => {
    return (
        <div className="grid grid-rows-2 gap-4 p-4 sm:p-6 md:p-8">
            <div className="flex flex-row items-start gap-3">
                <UserLogo className="w-6 h-8 sm:w-8 sm:h-10"/>
                <p className="text-xs sm:text-sm md:text-base text-left">{userMessage}</p>
            </div>
            <div className="flex flex-row items-start gap-3">
                <BotLogo className="w-6 h-8 sm:w-8 sm:h-10"/>
                <p className="text-xs sm:text-sm md:text-base text-left">{botMessage}</p>
            </div>
        </div>

    );
}

export default ChatRecord;