import '../App.css';
import {ReactComponent as Logo} from "../images/logo.svg";
import {ReactComponent as LandingBlobs} from "../images/landingBlobs.svg";
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';


const Home = () => {
  const {lang} = useParams();
  return (
    <>
        {/*<LandingBlobs className="fixed w-full h-full"/>*/}
        <Logo />
        <h1 className="font-semibold text-2xl sm:text-3xl md:text-4xl lg:text-5xl">Ready to Learn?</h1>
        <p className="text-base md:text-[1.25rem] mt-2">Pick a topic of interest</p>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-5">
            <Link className="bg-[#424242] text-xs sm:text-sm px-4 sm:px-6 md:px-8 lg:px-12 py-2 sm:py-3 md:py-4 lg:py-5 text-[#E2E2E2] border-solid border-2 rounded-full font-semibold cursor-pointer" to={`/chat/${lang}/sports`}>Hobbies and Sports</Link>
            <Link className="bg-[#424242] text-xs sm:text-sm px-4 sm:px-6 md:px-8 lg:px-12 py-2 sm:py-3 md:py-4 lg:py-5 text-[#E2E2E2] border-solid border-2 rounded-full font-semibold cursor-pointer" to={`/chat/${lang}/food`}>Food and Cuisine</Link>
            <Link className="bg-[#424242] text-xs sm:text-sm px-4 sm:px-6 md:px-8 lg:px-12 py-2 sm:py-3 md:py-4 lg:py-5 text-[#E2E2E2] border-solid border-2 rounded-full font-semibold cursor-pointer" to={`/chat/${lang}/geography-and-travel`}>Travel and Geography</Link>
            <Link className="bg-[#424242] text-xs sm:text-sm px-4 sm:px-6 md:px-8 lg:px-12 py-2 sm:py-3 md:py-4 lg:py-5 text-[#E2E2E2] border-solid border-2 rounded-full font-semibold cursor-pointer" to={`/chat/${lang}/news`}>Current Events and News</Link>
        </div>
    </>
  );
}

export default Home;
